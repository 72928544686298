*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #f5f5f5de;
}

*::-webkit-scrollbar-thumb {
    background: #ccc;
}

*::-webkit-scrollbar-thumb:hover {
    background: #aaadad;
}

body::-webkit-scrollbar-track {
    background-color: #00000036;
}

body::-webkit-scrollbar-thumb {
    background: #a1a1a1;
    border-radius: 5px;
}

body::-webkit-scrollbar-thumb:hover {
    background: #818181;
}

@media (min-width: 1200px) {
    body.sidebarClose {
        overflow: visible !important;
        padding-right: 0 !important;
    }
}

@media (min-width: 1700px) {
    *::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
}

.swal2-container {
    z-index: 99999 !important;
}


@media print {

    .noPrintElement {
        display: none !important;
    }

    * {
        box-shadow: none !important;
        border: none !important;
        padding: 0 !important;
        text-align: center !important;
    }

    html,
    body {
        max-width: 350px !important;
        max-height: 350px !important;
        overflow: hidden !important;
        margin: 0 auto !important;
        text-align: center;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    button {
        display: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .qrCodeWrap,
    .qrCodeWrap img {
        max-width: 350px !important;
        max-height: 350px !important;
        height: 350px !important;
        width: 350px !important;
        margin: 0 auto !important;
        text-align: center !important;
    }

    .MuiDialog-paper {
        margin: 0 !important;
        width: 100% !important;
        max-width: 350px !important;
        max-height: 350px !important;
    }

    .MuiDialogContent-root {
        padding: 0 !important;
        margin: 0 !important;
        padding-top: 0 !important;
    }

    .MuiDialogTitle-root {
        display: none !important;
    }

    .locationTabsMainWrap .MuiTabs-root {
        display: none;
    }
}